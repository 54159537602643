import { Stack } from '@teleforce/ui';
import styled, { css } from 'styled-components';

export interface StyledContainerProps {
  noPadding?: boolean;
  stickyHeader?: boolean;
  title?: string;
}

export const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.get(1.5)};
`;

export const StyledHeaderContainer = styled(Stack)`
  width: 100%;
  ${({ theme }) => css`
    background: ${theme.palette.background.main};
    padding: ${theme.spacing.get(5, 0, 4)};
  `}
`;

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ stickyHeader }) =>
    stickyHeader &&
    css`
      ${StyledHeaderContainer} {
        position: sticky;
        top: 0;
        z-index: ${({ theme }) => theme.zIndex.header};
      }
    `}

  ${({ theme }) => css`
    background: ${theme.palette.background.main};
    padding: ${theme.spacing.get(0, 4, 4)};
  `}

  ${({ noPadding, theme }) =>
    noPadding &&
    css`
      padding: ${theme.spacing.get(0)};
      ${StyledHeaderContainer} {
        padding: ${theme.spacing.get(7, 4, 0)};
      }
    `}
`;

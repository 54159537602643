import styled, { css } from 'styled-components';

interface SegmentedControlWrapperProps {
  optionsCount: number;
}

interface OptionProps {
  optionPosition: number;
  selected: boolean;
}

interface IndicatorProps {
  selectedOptionIndex: number;
}

export const SegmentedControlWrapper = styled.div<SegmentedControlWrapperProps>`
  --gap: ${(props) => props.theme.spacing.get(0.5)};
  height: ${(props) => props.theme.spacing.get(8)};
  display: grid;
  border-radius: ${(props) => props.theme.spacing.get(2)};
  background: ${(props) => props.theme.palette.neutral80};
  padding: var(--gap);
  gap: var(--gap);
  grid-template-columns: repeat(${(props) => props.optionsCount}, 1fr);
`;

export const Option = styled.div<OptionProps>`
  z-index: 1;
  grid-area: 1 / ${(props) => props.optionPosition} / 1 /
    ${(props) => props.optionPosition};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.palette.neutral10};
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  font-size: 13px;
  transition: 200ms;

  ${(props) =>
    props.selected &&
    css`
      color: ${props.theme.palette.neutral100};
    `};
`;

export const Indicator = styled.div<IndicatorProps>`
  --gap-offset: calc(var(--gap) * ${(props) => props.selectedOptionIndex});
  --option-offset: calc(${(props) => props.selectedOptionIndex} * 100%);
  --computed-offset: calc(var(--gap-offset) + var(--option-offset));
  transform: translateX(var(--computed-offset));

  -webkit-tap-highlight-color: transparent;
  grid-area: 1/1/1/1;
  transition: all 0.16s ease;
  background: ${(props) => props.theme.palette.primary100};
  border-radius: ${(props) => props.theme.spacing.get(2)};
  pointer-events: none;
`;

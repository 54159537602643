import { apiClient } from '../../api';
import { SpaceUser } from '../models';

export const updateProfile = async (data: SpaceUser): Promise<SpaceUser> => {
  const response = await apiClient.request({
    path: '/api/v1/space/profile/me',
    method: 'POST',
    body: SpaceUser.toDTO(data),
    withAuth: 'ifAvailable',
  });

  return SpaceUser.fromDTO(response.data);
};

import {
  attach,
  combine,
  createDomain,
  createEffect,
  createEvent,
  restore,
  sample,
} from 'effector';
import { loadProfile } from '../../user/network';
import type { SpaceUser } from '../../user/models';
import { Space, loadSpaces } from '../../spaces';
import { SpaceLocalStorage } from '../../spaces/storage';
import type { HttpClientAuthProvider } from '../../httpClient';
import { apiClient } from '../../api';

const domain = createDomain();

const spaceLocal = new SpaceLocalStorage();
const $spaces = domain.createStore<Space[]>([]);

const setActiveSpaceId = createEvent<number>();
const $activeSpaceId = domain.createStore<number | null>(null);
const $activeSpace = combine(
  $spaces,
  $activeSpaceId,
  (spaces, activeSpaceId) => spaces.find((x) => x.id === activeSpaceId) || null,
);

const $profile = domain.createStore<SpaceUser | null>(null);
const $viewerId = $profile.map((viewer) => viewer?.id ?? null);
const $isVisible = $profile.map((viewer) => viewer?.isVisible ?? true);
const getViewer = createEvent();
const getViewerFx = createEffect(loadProfile);
const $isPending = getViewerFx.pending;
const getViewerIfNeededFx = attach({
  source: $profile,
  effect: (viewer) => {
    if (viewer) return viewer;

    return getViewerFx();
  },
});

const loadSpacesFx = createEffect(loadSpaces);
const $loadSpacesError = restore(loadSpacesFx.failData, null);

const detectActiveSpaceFx = attach({
  source: $activeSpaceId,
  effect: async (activeSpaceId) => {
    if (activeSpaceId !== null) return activeSpaceId;

    const { data } = await loadSpacesFx();
    const lastSpaceId = await spaceLocal.getLastSpaceId();
    const lastSpace = data.find((x) => x.id === lastSpaceId) || data[0];

    if (!lastSpace) throw Error('No space found');

    return lastSpace.id;
  },
});

sample({
  clock: detectActiveSpaceFx.doneData,
  target: setActiveSpaceId,
});

sample({
  clock: setActiveSpaceId,
  target: $activeSpaceId,
});

sample({
  clock: loadSpacesFx.doneData,
  fn: (res) => res.data,
  target: $spaces,
});

sample({
  clock: $activeSpaceId,
  filter: Boolean,
  target: getViewer,
});

sample({
  clock: getViewer,
  target: getViewerFx,
});

sample({
  clock: getViewerFx.doneData,
  target: $profile,
});

$activeSpaceId.watch((spaceId) => {
  if (spaceId !== null) {
    spaceLocal.setItem('spaceId', spaceId);
    const authProvider: HttpClientAuthProvider = (options) => {
      const extraHeaders = {
        'x-space-id': spaceId.toString(),
      };
      // eslint-disable-next-line no-param-reassign
      options.headers = {
        ...options.headers,
        ...extraHeaders,
      };
      return options;
    };

    apiClient.addAuthProvider(authProvider);
  }
});

export const viewerEntity = {
  setActiveSpaceId,
  $activeSpaceId,
  $activeSpace,
  $profile,
  $viewerId,
  $isVisible,
  $isPending,
  $loadSpacesError,
  $spaces,
  getViewerFx,
  getViewerIfNeededFx,
  detectActiveSpaceFx,
  loadSpacesFx,
};

import { lazy, type FC, Suspense } from 'react';

const LazyComponent = lazy(() => import('./JsonViewComponent'));

interface JsonViewProps {
  value: object;
}

export const JsonView: FC<JsonViewProps> = (props) => {
  return (
    <Suspense>
      <LazyComponent {...props} />
    </Suspense>
  );
};

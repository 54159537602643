import i18n from 'i18next';
import ICU from 'i18next-icu';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { setLocale as setYupLocale } from 'yup';
import { createEffect, createStore, sample } from 'effector';
import { telegramService } from '../telegram';

const $locale = createStore(telegramService.locale);
const changeLocale = createEffect((lang: string) => {
  i18n.changeLanguage(lang);

  Telegram.WebApp.CloudStorage.setItem('locale', lang);
});

sample({
  clock: changeLocale,
  target: $locale,
});

Telegram.WebApp.CloudStorage.getItem<string>('locale', (err, locale) => {
  if (!err && locale) {
    changeLocale(locale);
  }
});

i18n
  .use(
    resourcesToBackend((lang, ns) => {
      if (ns === 'web-base') {
        return import(
          `../../../../../../libs/web-base/src/i18n/locales/${lang}.json`
        );
      }
      return import(`./locales/${lang}/${ns}.json`);
    }),
  )
  .use(initReactI18next)
  .use(ICU)
  .init({
    lng: telegramService.locale,
    fallbackLng: 'ru',
    supportedLngs: ['ru', 'en'],
    react: {
      useSuspense: true,
    },
  });

i18n.on('languageChanged', () => {
  const yupLocale = i18n.t('yup', { returnObjects: true });
  setYupLocale(yupLocale || null);
});

export default Object.assign(i18n, {
  $locale,
  changeLocale,
});

import { attach, createEvent, sample } from 'effector';
import { debounce } from 'patronum';
import { createRequest } from '../../../common';
import { updateProfile } from '../../../user';
import { viewerEntity } from '../../entities';
import { notificationService } from '../../../notifications';

const { fetchFx, $pending } = createRequest({
  callbackFn: updateProfile,
});

const toggleVisibility = createEvent();

const toggleVisibilityFx = attach({
  source: viewerEntity.$profile,
  effect: (profile) => {
    if (!profile) throw Error('No viewer profile found');

    return updateProfile({
      ...profile,
      isVisible: profile.isVisible,
    });
  },
});

const setVisibilityFx = attach({
  source: viewerEntity.$profile,
  effect: (profile, isVisible: boolean) => {
    if (!profile) throw Error('No viewer profile found');

    return updateProfile({
      ...profile,
      isVisible,
    });
  },
});

sample({
  source: viewerEntity.$profile,
  clock: toggleVisibility,
  fn: (profile) =>
    profile && {
      ...profile,
      isVisible: !profile.isVisible,
    },
  target: viewerEntity.$profile,
});

debounce({
  source: toggleVisibility,
  timeout: 500,
  target: toggleVisibilityFx,
});

sample({
  clock: fetchFx.doneData,
  target: viewerEntity.$profile,
});

fetchFx.doneData.watch(() => {
  notificationService.add('Данные обновлены!', {
    type: 'success',
  });
});

export const updateProfileModel = {
  fetchFx,
  $pending,
  toggleVisibility,
  toggleVisibilityFx,
  setVisibilityFx,
};

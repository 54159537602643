import { Expose, Transform, plainToClass } from 'class-transformer';
import type { SpaceUserDTO } from '../types';

export class SpaceUser {
  id!: number;

  @Expose({ name: 'first_name' })
  @Transform(({ value }) => {
    return value ?? '';
  })
  name?: string;

  @Expose({ name: 'last_name' })
  @Transform(({ value }) => {
    return value ?? '';
  })
  lastname?: string;

  about?: string;

  image?: string | null;

  @Transform(({ value }) => {
    if (!Array.isArray(value)) {
      return [];
    }

    return value.filter((item) => item.length > 0);
  })
  tags?: string[];

  @Transform(({ value }) => {
    if (!Array.isArray(value)) {
      return [];
    }

    return value.filter((item) => item.length > 0);
  })
  links?: string[];

  @Expose({ name: 'telegram_username' })
  username?: string | null;

  @Expose({ name: 'is_visible' })
  isVisible?: boolean;

  @Expose({ name: 'is_space_owner' })
  isSpaceOwner?: boolean;

  static fromDTO(dto: SpaceUserDTO): SpaceUser {
    return plainToClass(SpaceUser, dto);
  }

  static toDTO(item: Partial<SpaceUser>): Partial<SpaceUserDTO> {
    const { name, lastname, isVisible, username, isSpaceOwner, ...rest } = item;
    return {
      ...rest,
      first_name: name,
      last_name: lastname,
      is_visible: isVisible,
      telegram_username: username,
      is_space_owner: isSpaceOwner,
    };
  }
}

import styled, { css } from 'styled-components';
import { Typography } from '../../../../Typography';

export const DropDown = styled.div<{
  visible: boolean;
  position: { left: number; top: number };
}>`
  position: absolute;
  top: 30px;
  left: 0;
  display: none;
  overflow: auto;

  &:focus-visible {
    outline: none;
  }

  ${({ theme }) => css`
    top: ${theme.spacing.get(7.75)};
    left: 0;
    max-height: ${theme.spacing.get(50)};
    min-width: ${theme.spacing.get(50)};
    background: ${theme.palette.background.main};
    box-shadow: 0 0 0 1px ${theme.palette.neutral60};
    padding: ${theme.spacing.get(2)};
  `}

  ${({ position }) => {
    const leftOffset = `${position.left}px`;
    const topOffset = `${position.top}px`;

    return css`
      top: ${topOffset};
      left: ${leftOffset};
    `;
  }}

  ${({ visible }) =>
    visible &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const Suggestion = styled(Typography)<{ selected: boolean }>`
  ${({ theme }) => css`
    padding: ${theme.spacing.get(1, 2)};
    cursor: pointer;
  `}

  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.palette.neutral80};
    `}

  &:hover,
  &:focus {
    ${({ theme }) => css`
      background-color: ${theme.palette.neutral80};
    `}
  }
`;

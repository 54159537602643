import { type FC, useCallback } from 'react';
import type { NotificationItem } from '@teleforce/core';
import { Alert, SnackbarItem, type SnackbarItemProps } from '@teleforce/ui';

export interface NotificationViewProps
  extends Partial<Omit<SnackbarItemProps, 'onClose' | 'children'>> {
  item: NotificationItem;
  onClose?: (item: NotificationItem | NotificationItem['id']) => void;
}

export const NotificationView: FC<NotificationViewProps> = (props) => {
  const { item, onClose, ...rest } = props;

  const closeHandler = useCallback(() => {
    if (onClose) {
      onClose(item);
    }
  }, [item, onClose]);

  return (
    <SnackbarItem
      autoHideDuration={item.autoHideDuration || 0}
      provideOnClose
      onClose={closeHandler}
      {...rest}
    >
      <Alert preset={item.type} icon={item.icon}>
        {item.message}
      </Alert>
    </SnackbarItem>
  );
};

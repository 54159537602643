import { apiClient } from '../../api';
import { type AuthData } from '../types';

export interface RefreshTokenData {
  refresh_token: string;
}

export const refreshToken = async (
  data: RefreshTokenData,
): Promise<AuthData> => {
  const response = await apiClient.request({
    path: '/api/v1/auth/jwt/refresh_token',
    method: 'POST',
    silent: true,
    body: data,
    withAuth: false,
  });

  return {
    accessToken: response.data.access_token,
    refreshToken: response.data.refresh_token || null,
  };
};

import type { PreparedRequestOptions, RequestResponse } from './types';
import { RequestError } from './RequestError';

export interface XHROnProgressData {
  total: number;
  loaded: number;
  progress: number;
}

export interface XHRRequestOptions {
  onProgress?: (data: XHROnProgressData) => void;
  onUploadProgress?: (data: XHROnProgressData) => void;
}

const getProgressEventHandler = (
  handler: (data: XHROnProgressData) => void,
) => {
  return (event: ProgressEvent) => {
    if (event.lengthComputable) {
      handler({
        total: event.total,
        loaded: event.loaded,
        progress: event.loaded / event.total,
      });
    }
  };
};

const getResponseBody = (xhr: XMLHttpRequest) => {
  const contentType = xhr.getResponseHeader('content-type');
  if (
    xhr.responseText &&
    contentType &&
    contentType.startsWith('application/json')
  ) {
    return JSON.parse(xhr.responseText);
  }
  return null;
};

const getResponseHeaders = (xhr: XMLHttpRequest): Headers => {
  const rawHeaders = xhr.getAllResponseHeaders();
  const headerLines = rawHeaders.split('\u000d\u000a');
  const headers = new Headers();

  headerLines.forEach((line) => {
    const [key, ...values] = line.split(': ');
    if (key) {
      headers.append(key, values.join(': '));
    }
  });

  return headers;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const xhrRequest = <T = any>(
  options: PreparedRequestOptions,
  xhrOptions: XHRRequestOptions = {},
): Promise<RequestResponse<T>> => {
  return new Promise((resolve, reject) => {
    const { onProgress, onUploadProgress } = xhrOptions;

    const xhr = new XMLHttpRequest();
    xhr.open(options.method, options.url);
    Object.entries(options.headers).forEach(([key, value]) => {
      xhr.setRequestHeader(key, value);
    });

    if (onProgress) {
      xhr.onprogress = getProgressEventHandler(onProgress);
    }

    if (onUploadProgress) {
      xhr.upload.onprogress = getProgressEventHandler(onUploadProgress);
    }

    xhr.onerror = () => {
      const error = new RequestError(
        xhr.status,
        getResponseHeaders(xhr),
        getResponseBody(xhr),
      );
      reject(error);
    };

    xhr.onload = () => {
      const result: RequestResponse<T> = {
        statusCode: xhr.status,
        headers: getResponseHeaders(xhr),
        data: getResponseBody(xhr),
      };
      resolve(result);
    };

    xhr.send(options.body);
  });
};

import styled from 'styled-components';

export const ViewWrapper = styled.div`
  width: 100%;
  height: 600px;
  max-height: 90vh;
  overflow: auto;

  .w-json-view-container {
    padding: ${(p) => p.theme.spacing.get(8, 6)};
  }
`;

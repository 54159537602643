import { apiClient } from '../api';
import type { RequestOptions } from '../httpClient';
import { authService } from './services';

apiClient.addAuthProvider(async (options: RequestOptions) => {
  if (options.withAuth) {
    try {
      const accessToken = await authService.getValidAccessToken();
      // eslint-disable-next-line no-param-reassign
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    } catch (e) {
      if (options.withAuth !== 'ifAvailable') {
        throw new Error('Unauthorized');
      }
    }
  }
  return options;
});

apiClient.on('error', ({ error }) => {
  if (error.statusCode === 401) {
    authService.logout();
  }
});

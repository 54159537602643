import { type IStorage } from './types';

export class SessionStorage<KeyMap = { [key: string]: unknown }>
  implements IStorage<KeyMap>
{
  namespace: string;

  constructor(namespace: string) {
    this.namespace = namespace;
  }

  getFullKey(key: string): string {
    return `${this.namespace}/${key}`;
  }

  setItem = async <Key extends keyof KeyMap>(
    key: Key,
    value: KeyMap[Key] | null,
  ): Promise<void> => {
    if (value === null) {
      this.removeItem(key);
      return;
    }
    const valueStr = JSON.stringify(value);
    sessionStorage.setItem(this.getFullKey(key as string), valueStr);
  };

  getItem = async <Key extends keyof KeyMap>(
    key: Key,
  ): Promise<KeyMap[Key] | null> => {
    const data = sessionStorage.getItem(this.getFullKey(key as string));
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        return parsedData;
      } catch (e) {
        this.removeItem(key);
      }
    }

    return null;
  };

  removeItem = async <Key extends keyof KeyMap>(key: Key): Promise<void> => {
    sessionStorage.removeItem(this.getFullKey(key as string));
  };

  getAllKeys = async (): Promise<Array<string>> => {
    return Object.keys(sessionStorage).filter((x) =>
      x.startsWith(`${this.namespace}/`),
    );
  };

  clear = async (): Promise<void> => {
    const keys = await this.getAllKeys();
    keys.forEach((x) => {
      sessionStorage.removeItem(x);
    });
  };

  static isSupported(): boolean {
    try {
      if (sessionStorage && typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem('feature_test', 'yes');
        if (sessionStorage.getItem('feature_test') === 'yes') {
          sessionStorage.removeItem('feature_test');
          return true;
        }
        return false;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
}

import type { Node } from 'slate';

export enum EditorCustomElements {
  Style = 'style',
  Expression = 'expression',
}

export type Expression = {
  [key: string]: string | number | Expression;
};

export type CustomElement = {
  id?: number | string;
  type?: EditorCustomElements.Style | EditorCustomElements.Expression;
  content?: string;
  description?: string;
};

export type ParsedObject = Node &
  CustomElement & {
    children?: unknown[];
  };

import {
  attach,
  createDomain,
  createEvent,
  createStore,
  sample,
} from 'effector';

type SubmitAction = {
  title: string;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onOk: Function;
};

const domain = createDomain();
const reset = createEvent();

domain.onCreateStore((store) => store.reset(reset));

const add = createEvent<SubmitAction>();
const $current = domain.createStore<SubmitAction | null>(null);
const onOk = attach({
  source: $current,
  effect: async (action) => {
    if (!action) return null;

    return action.onOk();
  },
});

const $isPending = onOk.pending;

$current.on(add, (_, value) => value);

sample({
  clock: onOk.finally,
  target: reset,
});

export const submitActionModel = {
  add,
  onOk,
  $current,
  $isPending,
  reset,
};

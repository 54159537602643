import { SessionStorage } from '../../common';
import type { AuthData } from '../types';
import type { AuthStorage, AuthStorageKeyMap } from './types';

export class AuthSessionStorage
  extends SessionStorage<AuthStorageKeyMap>
  implements AuthStorage
{
  constructor() {
    super('@auth');
  }

  setAuthData = async (data: AuthData | null) => {
    await this.setItem('data', data);
  };

  getAuthData = (): Promise<AuthData | null> => {
    return this.getItem('data');
  };
}

import { createContext } from 'react';

export interface PageTitleUpdateContextValue {
  pageTitle: string;
  setSuffix: (suffix: string) => void;
  setTitle: (value: string) => void;
}

export const PageTitleContext = createContext<PageTitleUpdateContextValue>({
  pageTitle: document.title,
  setSuffix: () => {},
  setTitle: () => {},
});

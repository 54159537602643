import { apiClient } from '../../api';
import { SpaceUser } from '../models';

export const loadProfile = async (): Promise<SpaceUser> => {
  const response = await apiClient.request({
    path: '/api/v1/space/profile/me',
    method: 'GET',
    withAuth: 'ifAvailable',
  });

  return SpaceUser.fromDTO(response.data);
};

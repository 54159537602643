import type { FC } from 'react';
import { useNotificationReader } from '@teleforce/core';
import { Snackbar, type SnackbarProps } from '@teleforce/ui';
import { NotificationView } from './NotificationView';

export interface NotificationsProps extends Omit<SnackbarProps, 'children'> {
  maxItems?: number;
  preventDuplicate?: boolean;
}

export const Notifications: FC<NotificationsProps> = (props) => {
  const { maxItems = 3, preventDuplicate = false, ...rest } = props;

  const { items, close } = useNotificationReader({
    limit: maxItems,
    preventDuplicate,
  });

  return (
    <Snackbar {...rest}>
      {items.map((x) => (
        <NotificationView key={x.id} item={x} onClose={close} />
      ))}
    </Snackbar>
  );
};

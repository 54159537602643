import { useCallback, useEffect, useState } from 'react';
import type { NotificationItem } from './types';
import { notificationService } from './notificationService';

export interface UseNotificationReaderOptions {
  limit?: number;
  preventDuplicate?: boolean;
}

export interface UseNotificationReaderResult {
  items: Array<NotificationItem>;
  close: (item: NotificationItem | NotificationItem['id']) => void;
}

const compareItems = (a: NotificationItem, b: NotificationItem): boolean => {
  return a.message === b.message && a.type === b.type;
};

const readUniqueItems = (
  count: number,
  existItems: Array<NotificationItem>,
): Array<NotificationItem> => {
  if (count < 1) {
    return [];
  }
  const result: Array<NotificationItem> = [];

  while (result.length < count) {
    const item = notificationService.take(1)[0] || null;

    if (item === null) {
      break;
    }

    const isUnique =
      !existItems.some((x) => compareItems(x, item)) &&
      !result.some((x) => compareItems(x, item));

    if (isUnique) {
      result.push(item);
    }
  }

  return result;
};

export const useNotificationReader = (
  options: UseNotificationReaderOptions = {},
): UseNotificationReaderResult => {
  const { limit = 5, preventDuplicate = false } = options;

  const [items, setItems] = useState<Array<NotificationItem>>([]);

  const read = useCallback(() => {
    // Saving taken items for protect double call
    // https://github.com/facebook/react/issues/12856#issuecomment-390206425
    let newItems: Array<NotificationItem> | null = null;
    setItems((state) => {
      const diff = limit - state.length;
      if (diff > 0 && (!newItems || newItems.length === 0)) {
        if (preventDuplicate) {
          newItems = readUniqueItems(diff, state);
        } else {
          newItems = notificationService.take(diff);
        }
      }
      if (newItems) {
        return [...state, ...newItems];
      }
      return state;
    });
  }, [limit, preventDuplicate]);

  const close = useCallback(
    (item: NotificationItem | NotificationItem['id']) => {
      const id = typeof item === 'string' ? item : item.id;
      setItems((state) => state.filter((x) => x.id !== id));
      read();
    },
    [read],
  );

  useEffect(() => {
    read();
    return notificationService.on('add', read);
  }, [read]);

  return {
    items,
    close,
  };
};

import styled from 'styled-components';
import { Typography } from '../Typography';

export const Root = styled.div`
  padding: ${(props) => props.theme.spacing.get(3, 4)};
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing.get(2)};
  cursor: pointer;
  background: ${(props) => props.theme.palette.neutral100};
`;

export const Label = styled(Typography)`
  flex: 1;
`;

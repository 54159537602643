import styled, { css } from 'styled-components';

export interface StyleContainerProps {
  withBottomOffset?: boolean;
}

export const StyledScrollableContainer = styled.div<StyleContainerProps>`
  width: 100%;
  height: 100%;
  overflow: auto;

  ${({ theme, withBottomOffset }) => {
    let bottomPadding = 'none';

    if (withBottomOffset) {
      bottomPadding = theme.spacing.get(5);
    }

    return css`
      padding-bottom: ${bottomPadding};
    `;
  }}
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

import styled from 'styled-components';

export const DataGridMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  ${(p) => p.theme.typography.textSM};
  color: ${(p) => p.theme.palette.neutral10};
`;

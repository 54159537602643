import {
  Editor,
  Transforms,
  createEditor,
  Path,
  Range,
  Point,
  type BaseElement,
} from 'slate';
import { ReactEditor, withReact } from 'slate-react';
import { generateUUID } from '@teleforce/common';
import {
  EditorCustomElements,
  type CustomElement,
  type ParsedObject,
} from '../types';
import { flattenNodes, TransformCustomElementToNode } from '../helpers';

export class SlateManager {
  private editor: ReactEditor;

  private devMode?: boolean;

  private allowExpressions: boolean;

  constructor(allowExpressions = false, devMode = false) {
    this.allowExpressions = allowExpressions;
    this.devMode = devMode;

    const withLogging = (editor: ReactEditor) => {
      const { apply } = editor;
      // eslint-disable-next-line no-param-reassign
      editor.apply = (operation) => {
        // eslint-disable-next-line no-console
        console.log('Applying operation:', operation);
        apply(operation);
      };
      return editor;
    };

    const withInlines = (editor: ReactEditor): ReactEditor => {
      const { isInline, isElementReadOnly } = editor;

      // eslint-disable-next-line no-param-reassign
      editor.isElementReadOnly = (element: ParsedObject) =>
        element.type === EditorCustomElements.Style ||
        isElementReadOnly(element as BaseElement);

      // eslint-disable-next-line no-param-reassign
      editor.isInline = (element: ParsedObject) =>
        [EditorCustomElements.Style, EditorCustomElements.Expression].includes(
          element.type as EditorCustomElements,
        ) || isInline(element as BaseElement);

      return editor;
    };

    this.editor = this.devMode
      ? withLogging(withInlines(withReact(createEditor())))
      : withInlines(withReact(createEditor()));
  }

  public get EditorInstance(): ReactEditor {
    return this.editor;
  }

  selectAllNodes = (): void => {
    const firstPath: Path = [0];
    const lastNode = Editor.node(this.editor, Editor.end(this.editor, []));

    if (lastNode) {
      const [, lastPath] = lastNode;
      const range = Editor.range(this.editor, firstPath, lastPath);
      Transforms.select(this.editor, range);
    }
  };

  isNodeExists = (newNode: CustomElement): boolean => {
    if (!newNode.id && !newNode.type) {
      return false;
    }
    this.selectAllNodes();
    const nodeElement = Editor.nodes(this.editor, {
      match: (n: ParsedObject) => {
        const allNodes = flattenNodes(n.children as ParsedObject[]);
        const sameNode = allNodes.filter(
          (item) => item.type === newNode.type && item.id === newNode.id,
        );
        return Boolean(sameNode.length);
      },
    });
    const { value: isExistsNode, done } = nodeElement.next();
    return !done && !!isExistsNode;
  };

  addCustomElement = (
    customElement: CustomElement,
    title?: string,
    focused?: boolean,
  ): void => {
    const node = TransformCustomElementToNode(customElement, title);

    if (!node || this.isNodeExists(node)) return;

    this.insertNode(node, focused);
  };

  addExpression = (): void => {
    const node: ParsedObject = {
      type: EditorCustomElements.Expression,
      id: generateUUID(),
      children: [{ text: '' }],
    };

    if (!node || this.isNodeExists(node)) return;

    this.insertNode(node, true);
  };

  insertNode = (node: ParsedObject, focused?: boolean): void => {
    let insertPath: Path | null = null;
    if (this.editor.selection) {
      const [, currentPath] = Editor.node(this.editor, this.editor.selection);
      const nextPoint: Point | null | undefined = Editor.after(
        this.editor,
        currentPath,
      );
      insertPath = nextPoint ? nextPoint.path : null;
    }

    if (!insertPath) {
      const lastNodePath: Path = Editor.path(
        this.editor,
        Editor.end(this.editor, []),
      );
      insertPath = Path.next(lastNodePath);
    }

    Transforms.insertNodes(this.editor, node, { at: insertPath });

    if (focused) {
      Transforms.select(this.editor, insertPath);
    }
  };

  handleUpdateTextNode = (value: string, replace: boolean): void => {
    if (!this.editor.selection) return;

    const match = Editor.nodes(this.editor, {
      at: this.editor.selection,
      match: (n: ParsedObject) => n.type === EditorCustomElements.Expression,
      mode: 'highest',
    });

    const matchNode = match.next().value;
    if (matchNode) {
      const [, path] = matchNode;

      if (replace) {
        Transforms.delete(this.editor, { at: [...path, 0] });
      }

      Transforms.select(this.editor, Editor.end(this.editor, path));
      Transforms.insertText(this.editor, value);
    }
  };

  onKeyDown = (event): void => {
    const { selection } = this.editor;
    if (selection && Range.isCollapsed(selection)) {
      switch (event.key) {
        case 'ArrowLeft':
          event.preventDefault();
          Transforms.move(this.editor, { unit: 'offset', reverse: true });
          break;
        case 'ArrowRight':
          event.preventDefault();
          Transforms.move(this.editor, { unit: 'offset' });
          break;
        case '{':
          if (this.allowExpressions) {
            event.preventDefault();
            this.addExpression();
          }
          break;
        default:
          break;
      }
    }
  };
}

import {
  useCallback,
  type FC,
  type ReactNode,
  useState,
  useEffect,
  useRef,
} from 'react';
import { ExpressionWrapper } from './styled';
import type { Expression } from '../../types';
import { SuggestionDropdown } from '../SuggestionDropdown';

interface ExpressionInputProps {
  children: ReactNode &
    {
      props: {
        text: {
          text: string;
        };
      };
    }[];
  updateText?: (value: string, replace?: boolean) => void;
  onDropdownClose?: () => void;
  expressions?: Expression;
  onChange?: (value: string) => void;
}

const removeSymbols = (value: string) => {
  return value.replaceAll('{', '').replaceAll('"]', '').split('["');
};

export const ExpressionInput: FC<ExpressionInputProps> = ({
  children,
  expressions,
  onChange,
  updateText,
  onDropdownClose,
  ...rest
}) => {
  const [inputValue, setValue] = useState('');
  const [isDropdownVisible, setisDropdownVisible] = useState(false);
  const [suggestions, setSuggestions] = useState({});

  const wrapperRef = useRef<HTMLDivElement>(null);

  const filterExpressions = useCallback(
    (expressionsObject, acc = {}, inputString = '') => {
      if (!expressionsObject) return acc;
      if (!inputString) return { ...acc, ...expressionsObject };

      const keys = removeSymbols(inputString);
      const lastKeyPart = keys.pop();
      const currentObj = keys.reduce(
        (obj, key) => obj?.[key] ?? null,
        expressionsObject,
      );

      if (
        !currentObj ||
        typeof currentObj !== 'object' ||
        (currentObj[lastKeyPart as string] &&
          typeof currentObj[lastKeyPart as string] !== 'object')
      ) {
        return {};
      }

      // eslint-disable-next-line no-prototype-builtins
      if (currentObj.hasOwnProperty(lastKeyPart)) {
        return {
          ...acc,
          [lastKeyPart as string]: currentObj[lastKeyPart as string],
        };
      }

      const filteredObj = Object.fromEntries(
        Object.entries(currentObj).filter(([key]) =>
          key.startsWith(lastKeyPart as string),
        ),
      );

      return Object.keys(filteredObj).length ? { ...acc, ...filteredObj } : {};
    },
    [],
  );

  const autoComplete = useCallback(
    (value) => {
      if (typeof inputValue !== 'string' || typeof value !== 'string') {
        throw new Error('Both arguments should be of type string.');
      }
      const values = removeSymbols(inputValue);
      const lastElemIndex = values.length - 1;

      let isLastNode = false;
      if (values && values.length && expressions) {
        const arrayCopy = values.slice();
        arrayCopy.pop();
        const lastValue: Expression | string = arrayCopy.reduce(
          (acc: unknown, key: string) => {
            return acc ? acc[key] : undefined;
          },
          expressions,
        );

        if (
          lastValue &&
          lastValue[value] &&
          typeof lastValue[value] !== 'object'
        ) {
          isLastNode = true;
        }
      }

      if (values && values.length && value.startsWith(values[lastElemIndex])) {
        let suffix;
        let prefix;

        if (isLastNode) {
          suffix = '"]}';
        } else if (values.length > 1) {
          suffix = '"]["';
        } else {
          suffix = '["';
          prefix = '{';
        }
        return `${prefix ?? ''}${value.substring(
          values[lastElemIndex].length,
        )}${suffix}`;
      }

      return value;
    },
    [inputValue, expressions],
  );

  const onSuggestionChoose = useCallback(
    (value) => {
      const autocompleted = autoComplete(value);
      updateText?.(autocompleted);
    },
    [autoComplete, updateText],
  );

  useEffect(() => {
    const currentText = children[0].props.text.text;
    const newSuggestions = filterExpressions(expressions, {}, currentText);
    setSuggestions(newSuggestions);
    setisDropdownVisible(Object.keys(newSuggestions).length > 0);

    if (currentText && inputValue !== currentText) {
      setValue(children[0].props.text.text);
    }
  }, [children, expressions, filterExpressions, inputValue]);

  useEffect(() => {
    onChange?.(`{ ${inputValue} }`);
  }, [inputValue, onChange]);

  return (
    <ExpressionWrapper ref={wrapperRef} {...rest}>
      {children}
      <SuggestionDropdown
        wrapperRef={wrapperRef}
        suggestions={suggestions}
        isVisible={isDropdownVisible}
        onChoose={onSuggestionChoose}
      />
    </ExpressionWrapper>
  );
};

export const ERROR_DESCRIPTION: { [key: string]: string } = {
  REGISTER_INVALID_PASSWORD: 'Invalid password for registration',
  REGISTER_USER_ALREADY_EXISTS: 'User already exists',
  OAUTH_NOT_AVAILABLE_EMAIL: 'Email not available',
  OAUTH_USER_ALREADY_EXISTS: 'User already exists',
  LOGIN_BAD_CREDENTIALS: 'Login or password is invalid',
  LOGIN_USER_NOT_VERIFIED: 'User not verified',
  RESET_PASSWORD_BAD_TOKEN: 'Invalid token for password reset',
  RESET_PASSWORD_INVALID_PASSWORD: 'Invalid password for password reset',
  VERIFY_USER_BAD_TOKEN: 'Invalid token for user verification',
  VERIFY_USER_ALREADY_VERIFIED: 'User already verified',
  UPDATE_USER_EMAIL_ALREADY_EXISTS: 'Email already exists',
  UPDATE_USER_INVALID_PASSWORD: 'Invalid password for user data update',
  LOGIN_USER_NOT_SUPERUSER: 'Not enough permissions',
};

export const HTTP_ERROR_MESSAGES: { [key: number]: string } = {
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a teapot",
  419: 'Authentication Timeout (not in RFC 2616)',
  421: 'Misdirected Request [10];',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  449: 'Retry With',
  451: 'Unavailable For Legal Reasons',
  499: 'Client Closed Request (клиент закрыл соединение);',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  509: 'Bandwidth Limit Exceeded',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  520: 'Unknown Error',
  521: 'Web Server Is Down',
  522: 'Connection Timed Out',
  523: 'Origin Is Unreachable',
  524: 'A Timeout Occurred',
  525: 'SSL Handshake Failed',
  526: 'Invalid SSL Certificate ',
};

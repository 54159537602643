import {
  useState,
  type FC,
  useRef,
  useEffect,
  useMemo,
  type RefObject,
  useContext,
} from 'react';
import { createPortal } from 'react-dom';
import { DropDown, Suggestion } from './styled';
import { Stack } from '../../../../Stack';
import { EditorContext } from '../../EditorContext';

const DROPDOWN_BOTTOM_OFFSET = 4;

interface SuggestionDropdownProps {
  wrapperRef: RefObject<HTMLDivElement>;
  suggestions: Record<string, unknown>;
  isVisible: boolean;
  onChoose: (value: string) => void;
}

export const SuggestionDropdown: FC<SuggestionDropdownProps> = ({
  isVisible,
  wrapperRef,
  suggestions,
  onChoose,
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const styledEditorRef = useContext(EditorContext);

  const keys = Object.keys(suggestions);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY + DROPDOWN_BOTTOM_OFFSET,
        left: rect.left + window.scrollX,
      });
    }
  }, [wrapperRef]);

  useEffect(() => {
    const dropdownComponent = dropdownRef.current;
    const editorComponent = styledEditorRef?.current;

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown') {
        setActiveIndex((prevIndex) => (prevIndex + 1) % keys.length);
      }
      if (e.key === 'ArrowUp') {
        setActiveIndex(
          (prevIndex) => (prevIndex - 1 + keys.length) % keys.length,
        );
      }
      if (e.key === 'Enter') {
        onChoose(keys[activeIndex]);
      }
    };

    const setDropDownFocusByButton = (e) => {
      if (e.key === 'ArrowDown') {
        dropdownComponent?.focus();
        setActiveIndex(0);
      }
    };

    if (isVisible && dropdownComponent && editorComponent) {
      dropdownComponent.addEventListener('keydown', handleKeyDown);
      editorComponent?.addEventListener('keydown', setDropDownFocusByButton);
    }

    return () => {
      if (dropdownComponent && editorComponent) {
        dropdownComponent.removeEventListener('keydown', handleKeyDown);
        editorComponent?.removeEventListener(
          'keydown',
          setDropDownFocusByButton,
        );
      }
    };
  }, [isVisible, activeIndex, keys, styledEditorRef, onChoose]);

  const DropDownContent = useMemo(() => {
    return (
      <DropDown
        position={position}
        visible={isVisible}
        ref={dropdownRef}
        tabIndex={-1}
      >
        <Stack gap={2}>
          {keys.map((key, index) => {
            return (
              <Suggestion
                key={key}
                contentEditable="false"
                variant="textSM"
                onClick={() => onChoose(key)}
                selected={index === activeIndex}
              >
                {key}
              </Suggestion>
            );
          })}
        </Stack>
      </DropDown>
    );
  }, [position, isVisible, keys, activeIndex, onChoose]);

  useEffect(() => {
    if (!isVisible) {
      setActiveIndex(-1);
    }
  }, [isVisible]);

  return createPortal(DropDownContent, document.body);
};

import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import type { RequestListParams, RequestPagingResponse } from '../../common';
import { Space } from '../models';

interface SpacesFilters {}

export interface LoadSpacesData extends RequestListParams<SpacesFilters> {}

export const loadSpaces = async (
  data: LoadSpacesData = {},
): Promise<RequestPagingResponse<Space>> => {
  const params = apiRequestListParamsMapper(data);

  const response = await apiClient.request({
    path: '/api/v1/space/joined',
    method: 'GET',
    params,
    withAuth: 'ifAvailable',
  });

  return apiResponsePagingMapper<Space>(response.data, (x) => Space.fromDTO(x));
};

import { Stack, Typography } from '@teleforce/ui';
import styled, { css } from 'styled-components';

export const SelectHolder = styled.div`
  width: 100%;
`;

export const SpaceOptionContainer = styled(Stack)`
  ${({ theme }) => css`
    padding: ${theme.spacing.get(3, 0)};
    width: 100%;
    overflow: hidden;
  `}
`;

export const SpaceDescription = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.neutral20};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40px;
  `}
`;

export const SpaceTitle = styled(Typography)`
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

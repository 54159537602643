/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  RequestPagingParams,
  RequestListParams,
  RequestPagingResponse,
  RequestSortParams,
} from '../../common';

export const apiRequestPagingParamsMapper = <T = Record<string, any>>(
  data: RequestPagingParams & T,
) => {
  const { page, pageSize, ...rest } = data;

  return {
    page,
    page_size: pageSize,
    ...rest,
  };
};

export const apiReqestSortParamsMapper = <T = Record<string, any>>(
  data: RequestSortParams & T,
) => {
  const { field, direction, ...rest } = data;

  if (field) {
    return {
      ordering: `${direction === 'desc' ? '-' : ''}${field}`,
      ...rest,
    };
  }

  return rest;
};

export const apiReqestFilterParamsMapper = <
  T extends object = Record<string, any>,
>(
  data: T,
) => {
  const result = {};

  Object.keys(data).forEach((key) => {
    if (data[key]) {
      if (data[key] instanceof Date) {
        result[key] = data[key].toISOString().replace('Z', '');
      } else {
        result[key] = data[key];
      }
    }
  });

  return result;
};

export const apiRequestListParamsMapper = (data: RequestListParams) => {
  const { paging, filters, sort, ...rest } = data;

  let result = rest;
  if (paging) {
    result = {
      ...apiRequestPagingParamsMapper(paging),
      ...result,
    };
  }
  if (sort) {
    result = {
      ...apiReqestSortParamsMapper(sort),
      ...result,
    };
  }
  if (filters) {
    result = {
      ...apiReqestFilterParamsMapper(filters),
      ...result,
    };
  }

  return result;
};

export const apiResponsePagingMapper = <T = any>(
  data: any,
  itemMapper?: (item: any) => T,
): RequestPagingResponse<T> => {
  let totalCount: number | null = null;
  let hasNext: boolean = false;
  let items: Array<T> = [];
  if (Array.isArray(data)) {
    totalCount = data.length;
    items = data;
  } else if (data.founds) {
    totalCount = data.search_options.count ?? null;
    items = data.founds;
    hasNext = data.founds.length === data.search_options.page_size;
  } else {
    throw new Error('Unknown response format');
  }

  if (itemMapper) {
    items = items.map(itemMapper);
  }

  return {
    totalCount,
    hasNext,
    data: items,
  };
};

import styled from 'styled-components';

export const ViewWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const CodeWrapper = styled.div`
  width: 100%;
  height: 520px;
  max-height: calc(95vh - 70px);
  overflow: hidden;

  .cm-content {
    padding-top: 16px;
  }
`;

export const Footer = styled.div`
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${(p) => p.theme.spacing.get(4)};
  padding: ${(p) => p.theme.spacing.get(0, 6)};
  border-top: 1px solid ${(p) => p.theme.palette.neutral60};
`;

export const ErrorMessage = styled.div`
  margin-right: auto;
  ${(p) => p.theme.typography.textXS};
  color: ${(p) => p.theme.palette.danger100};
`;

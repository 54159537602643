import { type IStorage } from './types';

export class CookieStorage<KeyMap = { [key: string]: unknown }>
  implements IStorage<KeyMap>
{
  namespace: string;

  constructor(namespace: string) {
    this.namespace = namespace;
  }

  private getFullKey(key: string): string {
    return `${this.namespace}/${key}`;
  }

  private setCookie(name: string, value: string) {
    document.cookie = `${name}=${value};path=/`;
  }

  private getCookie(cookie: string): string | null {
    const name = `${cookie}=`;
    const cookiesArray = document.cookie.split(';');

    const foundCookie = cookiesArray.find((item) => {
      const trimmedCookie = item.trim();
      return trimmedCookie.indexOf(name) === 0;
    });

    if (foundCookie) {
      const [, value] = foundCookie.trim().split('=');
      return value;
    }

    return null;
  }

  private deleteCookie(name: string) {
    this.setCookie(name, '');
  }

  async setItem<Key extends keyof KeyMap>(
    key: Key,
    value: KeyMap[Key] | null,
  ): Promise<void> {
    const fullKey = this.getFullKey(key as string);
    const valueStr = value === null ? null : JSON.stringify(value);

    if (valueStr === null) {
      this.deleteCookie(fullKey);
    } else {
      this.setCookie(fullKey, valueStr);
    }
  }

  async getItem<Key extends keyof KeyMap>(
    key: Key,
  ): Promise<KeyMap[Key] | null> {
    const fullKey = this.getFullKey(key as string);
    const cookieValue = this.getCookie(fullKey);
    if (cookieValue) {
      try {
        const parsedData = JSON.parse(cookieValue);
        return parsedData;
      } catch (e) {
        this.removeItem(key);
      }
    }

    return null;
  }

  async removeItem<Key extends keyof KeyMap>(key: Key): Promise<void> {
    const fullKey = this.getFullKey(key as string);
    this.deleteCookie(fullKey);
  }

  async getAllKeys(): Promise<Array<string>> {
    const cookies = document.cookie
      .split(';')
      .map((cookie) => cookie.split('=')[0].trim());
    return cookies.filter((cookieName) =>
      cookieName.startsWith(`${this.namespace}/`),
    );
  }

  async clear(): Promise<void> {
    const keys = await this.getAllKeys();
    keys.forEach((cookieName) => this.deleteCookie(cookieName));
  }

  static isSupported(): boolean {
    try {
      if (typeof document !== 'undefined' && 'cookie' in document) {
        document.cookie = 'feature_test=yes; max-age=60';
        if (document.cookie.indexOf('feature_test=yes') !== -1) {
          document.cookie =
            'feature_test=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
          return true;
        }
        return false;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
}

import { type IStorage } from './types';

export class InMemoryStorage<KeyMap = { [key: string]: unknown }>
  implements IStorage<KeyMap>
{
  private storage: { [key: string]: string } = {};

  namespace: string;

  constructor(namespace: string) {
    this.namespace = namespace;
  }

  getFullKey(key: string): string {
    return `${this.namespace}/${key}`;
  }

  setItem = async <Key extends keyof KeyMap>(
    key: Key,
    value: KeyMap[Key] | null,
  ): Promise<void> => {
    if (value === null) {
      this.removeItem(key);
      return;
    }
    const valueStr = JSON.stringify(value);
    this.storage[this.getFullKey(key as string)] = valueStr;
  };

  getItem = async <Key extends keyof KeyMap>(
    key: Key,
  ): Promise<KeyMap[Key] | null> => {
    const data = this.storage[this.getFullKey(key as string)];
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        return parsedData;
      } catch (e) {
        this.removeItem(key);
      }
    }

    return null;
  };

  removeItem = async <Key extends keyof KeyMap>(key: Key): Promise<void> => {
    delete this.storage[this.getFullKey(key as string)];
  };

  getAllKeys = async (): Promise<Array<string>> => {
    return Object.keys(this.storage).filter((x) =>
      x.startsWith(`${this.namespace}/`),
    );
  };

  clear = async (): Promise<void> => {
    const keys = await this.getAllKeys();
    keys.forEach((key) => {
      delete this.storage[key];
    });
  };

  static isSupported(): boolean {
    return true;
  }
}

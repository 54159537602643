import {
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
  type FocusEvent,
  useMemo,
  useEffect,
} from 'react';
import { Editor, Transforms, type Descendant, Range, Element } from 'slate';
import { ReactEditor, Slate, useSelected } from 'slate-react';
import { TransformNodesToText } from './helpers';
import {
  EditorCustomElements,
  type CustomElement,
  type ParsedObject,
  type Expression,
} from './types';
import { ExpressionInput, PresetButton } from './elements';
import { StyledEditor } from './styled';
import { SlateManager } from './SlateManager';
import { EditorContext } from './EditorContext';

const IS_ENABLED_DEV_MODE = false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EditableWrapper = forwardRef<any, any>((props, ref) => {
  return (
    <div style={{ width: '100%' }} ref={ref}>
      <StyledEditor {...props} />
    </div>
  );
});

interface CustomEditorProps {
  placeholder?: string;
  initialValue?: ParsedObject[];
  allowExpressions?: boolean;
  disabled?: boolean;
  error?: string | boolean;
  expressions?: Expression;
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (value: string) => void;
}

export interface CustomEditorRef {
  addCustomElement: (
    customElement: CustomElement,
    title?: string,
    focused?: boolean,
  ) => void;
}

export const CustomEditor = forwardRef<CustomEditorRef, CustomEditorProps>(
  (
    {
      placeholder = '',
      initialValue = [{ children: [{ text: '' }] }],
      allowExpressions = false,
      disabled = false,
      error = false,
      expressions = {},
      onChange,
      onFocus,
      onBlur,
    },
    ref,
  ) => {
    const slateManager = useMemo(
      () => new SlateManager(allowExpressions, IS_ENABLED_DEV_MODE),
      [allowExpressions],
    );
    const editor = useMemo(() => slateManager.EditorInstance, [slateManager]);
    const localStyledEditorRef = useRef<HTMLDivElement | null>(null);
    const contextStyledEditorRef = useRef<HTMLDivElement | null>(null);

    const Text = useCallback(({ attributes, children, isElement }) => {
      if (isElement) {
        return <div {...attributes}>{children}</div>;
      }

      return <span {...attributes}>{children}</span>;
    }, []);

    const ExpressionBlock = useCallback(
      ({ attributes, element, children }) => {
        const { selection } = editor;
        let isFocused = false;

        if (selection) {
          const path = ReactEditor.findPath(editor, element);

          if (Range.includes(selection, path)) {
            isFocused = true;
          }
        }

        const selected = useSelected();

        return (
          <>
            <span contentEditable={false} style={{ fontSize: 0 }}>
              {String.fromCodePoint(160)}
            </span>
            <ExpressionInput
              isFocused={isFocused}
              data-playwright-selected={selected}
              updateText={slateManager.handleUpdateTextNode}
              expressions={expressions}
              {...attributes}
            >
              {children}
            </ExpressionInput>
            <span contentEditable={false} style={{ fontSize: 0 }}>
              {String.fromCodePoint(160)}
            </span>
          </>
        );
      },
      [editor, expressions, slateManager.handleUpdateTextNode],
    );

    const StyleButton = useCallback(
      ({ attributes, element, children }) => {
        const handleExpandStyle = () => {
          const originalIsElementReadOnly = editor.isElementReadOnly;
          editor.isElementReadOnly = () => false;
          slateManager.selectAllNodes();

          const ButtonElement = Editor.nodes(editor, {
            match: (n: ParsedObject) => {
              if (!n.id || !n.type) {
                return false;
              }
              return (
                !Editor.isEditor(n) &&
                Element.isElement(n) &&
                n.type === EditorCustomElements.Style &&
                n.id === element.id
              );
            },
          });

          const [elem] = Array.from(ButtonElement);

          if (elem && elem.length) {
            const item: ParsedObject = elem[0];
            Transforms.select(editor, elem[1]);
            Transforms.removeNodes(editor, { at: elem[1] });
            Editor.insertFragment(editor, [{ text: ` ${item.description} ` }]);
          }

          editor.isElementReadOnly = originalIsElementReadOnly;
        };

        const handleDeleteStyle = () => {
          slateManager.selectAllNodes();
          Transforms.removeNodes(editor, {
            match: (n: ParsedObject) => {
              if (!n.id || !n.type) {
                return false;
              }
              return (
                !Editor.isEditor(n) &&
                Element.isElement(n) &&
                n.type === EditorCustomElements.Style &&
                n.id === element.id
              );
            },
          });
        };

        return (
          <>
            <span contentEditable={false} style={{ fontSize: 0 }}>
              {String.fromCodePoint(160)}
            </span>
            <PresetButton
              content={element.content}
              description={element.description}
              onConfirm={handleExpandStyle}
              onCancel={handleDeleteStyle}
              {...attributes}
            >
              {children}
            </PresetButton>
            <span contentEditable={false} style={{ fontSize: 0 }}>
              {String.fromCodePoint(160)}
            </span>
          </>
        );
      },
      [editor, slateManager],
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          addCustomElement: slateManager.addCustomElement,
        };
      },
      [slateManager],
    );

    const renderElement = useCallback(
      (props) => {
        switch (props.element.type) {
          case EditorCustomElements.Style:
            return <StyleButton {...props} />;
          case EditorCustomElements.Expression:
            return <ExpressionBlock {...props} />;
          default:
            return <Text {...props} isElement />;
        }
      },
      [ExpressionBlock, StyleButton, Text],
    );

    const handleChange = useCallback(
      (editorNodes: Descendant[]) => {
        const result = TransformNodesToText(editorNodes);
        onChange?.(result);
      },
      [onChange],
    );

    const handleFocus = useCallback(
      (event: FocusEvent<HTMLTextAreaElement>) => {
        onFocus?.(event);
      },
      [onFocus],
    );

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLTextAreaElement>) => {
        onBlur?.(event);
      },
      [onBlur],
    );

    useEffect(() => {
      if (localStyledEditorRef.current) {
        contextStyledEditorRef.current = localStyledEditorRef.current;
      }
    }, [localStyledEditorRef, contextStyledEditorRef]);

    return (
      <EditorContext.Provider value={contextStyledEditorRef}>
        <Slate
          editor={editor}
          initialValue={initialValue}
          onChange={handleChange}
        >
          <EditableWrapper
            disabled={disabled}
            error={error ?? ''}
            ref={localStyledEditorRef}
            placeholder={placeholder}
            renderElement={renderElement}
            renderLeaf={(props) => <Text {...props} />}
            // This hack is used for setting focus state on the first render
            onClick={handleFocus}
            onBlur={handleBlur}
            onKeyDown={slateManager.onKeyDown}
          />
        </Slate>
      </EditorContext.Provider>
    );
  },
);

import { forwardRef, type FC, type ReactNode, useCallback } from 'react';
import { BrushIcon } from '@teleforce/icons';
import { Popconfirm } from '../../../../Popconfirm';
import { StyledButton } from './styled';

export interface PresetButtonProps {
  content: string;
  description: string;
  children: ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const PresetButton: FC<PresetButtonProps> = forwardRef((props, ref) => {
  const { content, description, children, onConfirm, onCancel } = props;

  const handleConfirm = useCallback(() => {
    onConfirm?.();
  }, [onConfirm]);

  const handleCancel = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <Popconfirm
      arrow
      placement="bottom"
      okText="Expand"
      cancelText="Delete"
      title={content}
      description={description}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    >
      <StyledButton
        ref={ref}
        type="button"
        variant="contained"
        color="base"
        size="xs"
        contentEditable={false}
        startIcon={<BrushIcon />}
      >
        {children}
      </StyledButton>
    </Popconfirm>
  );
});

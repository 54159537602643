import type { ReactNode } from 'react';

export enum NotificationType {
  Neutral = 'neutral',
  Info = 'info',
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
}

export interface NotificationItem {
  id: string;
  type: NotificationType | `${NotificationType}`;
  message: ReactNode;
  icon?: ReactNode;
  autoHideDuration?: number | null;
}

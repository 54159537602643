import { createDomain, createEffect, type Domain, type Effect } from 'effector';

type Params<Request, Response> = {
  domain?: Domain;
  callbackFn:
    | Effect<Request, Response>
    | ((payload: Request) => Promise<Response>);
};

const defaultDomain = createDomain();

export const createRequest = <Request, Response>({
  domain = defaultDomain,
  callbackFn,
}: Params<Request, Response>) => {
  const fetchFx = createEffect(callbackFn);

  const $error = domain.createStore<null | Error>(null);
  const $data = domain.createStore<Response | null>(null);

  $error.on(fetchFx.failData, (_, error) => error).reset(fetchFx);
  $data.on(fetchFx.doneData, (_, data) => data);

  return {
    fetchFx,
    $error,
    $data,
    $pending: fetchFx.pending,
  };
};

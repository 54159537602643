import { CalendarIcon, CloseIcon } from '@teleforce/icons';
import {
  type FC,
  type MouseEvent,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TextInput, type TextInputProps } from '../TextInput';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InputView = forwardRef<any, any>((props, ref) => {
  const { value, onClick, onChange, ...rest } = props;

  const clear = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (onChange) {
        onChange({
          target: {
            value: '',
          },
        });
      }
    },
    [onChange],
  );

  return (
    <TextInput
      ref={ref}
      onClick={onClick}
      endIcon={value ? <CloseIcon onClick={clear} /> : <CalendarIcon />}
      value={value}
      readOnly
      {...rest}
    />
  );
});

export interface DateInputProps
  extends Omit<TextInputProps, 'value' | 'onChange' | 'ref'> {
  value?: string | null;
  showTimeSelect?: boolean;
  onChange?: (value: string) => void;
}

export const DateInput: FC<DateInputProps> = (props) => {
  const { value, onChange, showTimeSelect, placeholder, ...rest } = props;

  const format = useMemo(() => {
    if (showTimeSelect) return 'dd/MM/yyyy hh:mm';

    return 'dd/MM/yyyy';
  }, [showTimeSelect]);

  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      dateFormat={format}
      showTimeSelect={showTimeSelect}
      placeholderText={placeholder}
      customInput={<InputView {...rest} />}
    />
  );
};

import { UniversalStorage } from '../../common';
import type { SpaceStorage, SpaceStorageKeyMap } from './types';

export class SpaceLocalStorage
  extends UniversalStorage<SpaceStorageKeyMap>
  implements SpaceStorage
{
  constructor() {
    super('@space');
  }

  setLastSpaceId = async (spaceId: number | null) => {
    await this.setItem('spaceId', spaceId);
  };

  getLastSpaceId = (): Promise<number | null> => {
    return this.getItem('spaceId');
  };
}

import type { CustomElement, ParsedObject } from '../types';

export const TransformCustomElementToNode = (
  customElement: CustomElement,
  title?: string,
): ParsedObject => {
  return {
    ...customElement,
    children: [{ text: title ?? 'Element' }],
  };
};

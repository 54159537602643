import { flattenNodes } from './FlattenNodes';
import type { ParsedObject } from '../types';

export const TransformNodesToText = (values: ParsedObject[]): string => {
  let result = '';
  const flatNodes = flattenNodes(values);

  const childrenWithText = flatNodes[0].children as ParsedObject[];
  if (childrenWithText && childrenWithText.length) {
    const parsed = childrenWithText.map((item: ParsedObject) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const content = item.description ?? item.text ?? item.children[0].text;

      if (content && content.length) {
        return ` ${content} `;
      }

      return '';
    });

    if (parsed && parsed.length) {
      result = parsed.join('').replaceAll('  ', ' ');
    }
    return result;
  }
  return '';
};

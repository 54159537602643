import { lazy, type FC, Suspense } from 'react';
import type { CodeEditorProps } from './types';
import { StretchLoader } from '../StretchLoader';

export * from './types';

const LazyComponent = lazy(() => import('./CodeEditorComponent'));

export const CodeEditor: FC<CodeEditorProps> = (props) => {
  return (
    <Suspense fallback={<StretchLoader />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

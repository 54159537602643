import { enUS, ru } from 'date-fns/locale';

export const sizes = {
  mobile: 400,
  desktop: 768,
};

export const devices = Object.keys(sizes).reduce(
  (accumulator, currentValue) => {
    return {
      ...accumulator,
      [currentValue]: `(min-width: ${sizes[currentValue]}px)`,
    };
  },
  { mobile: 0, tablet: 0, desktop: 0 },
);

export const UserLocaleMapping = {
  ru,
  en: enUS,
};

export const DateFormatMapping = {
  dayFirstFull: 'dd.MM.yyyy, HH:mm',
  monthFirstFull: 'MM.dd.yyyy, HH:mm',
  dayFirstShort: 'dd.MM.yy',
  monthFirstShort: 'MM.dd.yy',
  time: 'HH:mm',
};

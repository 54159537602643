import { Expose, plainToClass } from 'class-transformer';
import type { SpaceDTO } from '../types';

export class Space {
  id!: number;

  name?: string;

  description?: string;

  @Expose({ name: 'is_staff' })
  isStaff?: boolean = false;

  static fromDTO(dto: SpaceDTO): Space {
    return plainToClass(Space, dto);
  }
}

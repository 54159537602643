import type { FC } from 'react';
import { Image, Stack, Typography } from '@teleforce/ui';
import { useTranslation } from 'react-i18next';
import { ImageContainer, StyledPlaceholderContainer } from './styled';

export const RotationPlaceHolder: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledPlaceholderContainer>
      <Stack
        direction="column"
        gap={8}
        alignItems="center"
        justifyContent="center"
      >
        <ImageContainer>
          <Image type="skeleton" src="logo.svg" alt="logo" />
        </ImageContainer>
        <Stack gap={1} direction="column" alignItems="center">
          <Typography variant="headerXS">{t('rotateDeviceTitle')}</Typography>
          <Typography color="neutral10">
            {t('rotateDeviceDescription')}
          </Typography>
        </Stack>
      </Stack>
    </StyledPlaceholderContainer>
  );
};

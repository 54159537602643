import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import type { ThemeTypographyKey } from '../../../../themes';

interface StyledLinkProps {
  variant?: ThemeTypographyKey;
}

export const StyledTabLink = styled(NavLink)<StyledLinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;

  ${({ theme, variant = 'base' }) => css`
    ${theme.typography[variant]};
    color: ${theme.palette.primary100};
    border-radius: ${theme.spacing.get(3)};
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
    background: ${theme.palette.primary10};
    padding: ${theme.spacing.get(2.5)};
  `}

  &:visited {
    color: ${({ theme }) => theme.palette.primary100};
  }

  &.active {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
    ${({ theme }) => css`
      background: ${theme.palette.primary100};
      color: ${theme.palette.neutral100};
    `}
  }
`;

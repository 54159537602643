import {
  createEvent,
  createStore,
  type EventCallable,
  type StoreWritable,
} from 'effector';
import type { TelegramWebApps } from '../../../declarations/telegram';

type Palette = 'light' | 'dark';
class TelegramService {
  instance: TelegramWebApps.WebApp;

  initData: TelegramWebApps.WebApp['initData'];

  $theme: StoreWritable<Palette>;

  queryId?: string;

  userId?: number;

  locale: string;

  setTheme: EventCallable<Palette>;

  constructor(tg: TelegramWebApps.WebApp) {
    this.instance = tg;
    this.initData = tg.initData;
    this.$theme = createStore(tg.colorScheme);
    this.queryId = tg.initDataUnsafe?.query_id;
    this.userId = tg.initDataUnsafe?.user?.id;
    this.locale = tg.initDataUnsafe.user?.language_code === 'en' ? 'en' : 'ru';
    this.instance.isClosingConfirmationEnabled = false;
    this.setTheme = createEvent();

    this.$theme.on(this.setTheme, (_, value) => value);
  }

  onStart = () => this.instance.expand();

  onClose = () => this.instance.close();

  openTelegramLink = (link: string) => {
    return new Promise<void>((resolve) => {
      this.instance.openTelegramLink(link);
      setTimeout(resolve, 1000);
    });
  };

  openLink = (link: string) => {
    return new Promise<void>((resolve) => {
      this.instance.openLink(link);
      setTimeout(resolve, 1000);
    });
  };
}

export const telegramService = new TelegramService(Telegram.WebApp);

Telegram.WebApp.CloudStorage.getItem<Palette>('theme', (err, theme) => {
  if (!err && theme) {
    telegramService.setTheme(theme);
  }
});

telegramService.$theme.watch((palette) => {
  Telegram.WebApp.CloudStorage.setItem('theme', palette);
});

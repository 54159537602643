import { createContext } from 'react';

export interface AuthContextValue {
  initialized: boolean;
  authorized: boolean;
  logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextValue>({
  initialized: false,
  authorized: false,
  logout: async () => {},
});

import type { ReactNode } from 'react';
import { Listener, generateUUID } from '@teleforce/common';
import { type NotificationItem, NotificationType } from './types';

interface EventMap {
  add: NotificationItem;
  change: Array<NotificationItem>;
}

interface Options extends Partial<Omit<NotificationItem, 'message'>> {}

class NotificationService extends Listener<EventMap> {
  items: Array<NotificationItem> = [];

  defaultOptions: Options = {
    type: NotificationType.Info,
    autoHideDuration: 5000,
  };

  add = (message: ReactNode, options: Options = {}) => {
    const item: NotificationItem = {
      id: generateUUID(),
      type: NotificationType.Info,
      ...this.defaultOptions,
      ...options,
      message,
    };

    this.items.push(item);

    this.trigger('add', item);
    this.trigger('change', this.items);
  };

  take = (amount = 1): Array<NotificationItem> => {
    const items = this.items.splice(0, amount);

    this.trigger('change', this.items);

    return items;
  };

  clear = () => {
    this.items = [];

    this.trigger('change', []);
  };
}

export const notificationService = new NotificationService();

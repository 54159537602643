import React from 'react';
import ReactDOM from 'react-dom/client';
import { apiClient } from '@teleforce/core';
import { App } from './App';
import { config, initConfig } from './config';

const initializeApp = async () => {
  await initConfig();

  apiClient.defaultOptions = {
    baseUrl: config.api.baseUrl,
  };

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};

initializeApp().catch((error) => {
  // eslint-disable-next-line no-console
  console.error('Failed to initialize app:', error);
});

import { IconButton, Typography } from '@teleforce/ui';
import styled from 'styled-components';

export const Root = styled.main<{ noPadding: boolean }>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  --padding: ${(props) => (props.noPadding ? 0 : props.theme.spacing.get(4))};
`;

export const HeaderBox = styled.header`
  display: flex;
  align-items: center;
  padding: var(--padding);
  gap: ${(props) => props.theme.spacing.get(2)};
  padding-bottom: 0;
`;

export const GoBackButton = styled(IconButton)`
  width: 20px;
  height: 20px;
  overflow: hidden;
`;

export const ContentBox = styled.section`
  overflow: auto;
  flex: 1;
  padding: var(--padding);
`;

export const FooterBox = styled.footer`
  display: flex;
  align-items: center;
  padding: var(--padding);
  gap: ${(props) => props.theme.spacing.get(2)};
`;

export const Title = styled(Typography)`
  flex: 1;
`;

import { CookieStorage } from './CookieStorage';
import { InMemoryStorage } from './InMemoryStorage';
import { LocalStorage } from './LocalStorage';
import type { IStorage } from './types';

export class UniversalStorage<KeyMap = { [key: string]: unknown }>
  implements IStorage<KeyMap>
{
  private storage: IStorage<KeyMap>;

  namespace: string;

  constructor(namespace: string) {
    this.namespace = namespace;

    if (LocalStorage.isSupported()) {
      this.storage = new LocalStorage<KeyMap>(this.namespace);
    } else if (CookieStorage.isSupported()) {
      this.storage = new CookieStorage<KeyMap>(this.namespace);
    } else {
      this.storage = new InMemoryStorage<KeyMap>(this.namespace);
    }
  }

  async setItem<Key extends keyof KeyMap>(
    key: Key,
    value: KeyMap[Key] | null,
  ): Promise<void> {
    return this.storage.setItem(key, value);
  }

  async getItem<Key extends keyof KeyMap>(
    key: Key,
  ): Promise<KeyMap[Key] | null> {
    return this.storage.getItem(key);
  }

  async removeItem<Key extends keyof KeyMap>(key: Key): Promise<void> {
    return this.storage.removeItem(key);
  }

  async getAllKeys(): Promise<Array<string>> {
    return this.storage.getAllKeys();
  }

  async clear(): Promise<void> {
    return this.storage.clear();
  }
}

import { createContext } from 'react';
import type { RequestError } from '../../httpClient';
import type { Space } from '../models';

export interface SpaceContextValue {
  currentSpaceId: number | null;
  currentSpace: Space | null;
  spaces: Space[] | null;
  isLoading: boolean;
  error: Error | RequestError | null;
  refresh: () => void;
  setSpaceId: (id: number) => void;
}

export const SpaceContext = createContext<SpaceContextValue>({
  currentSpaceId: null,
  currentSpace: null,
  spaces: null,
  isLoading: false,
  error: null,
  refresh: () => {},
  setSpaceId: () => {},
});

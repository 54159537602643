import { EditorCustomElements, type ParsedObject } from '../types';

export const flattenNodes = (arr: ParsedObject[]) => {
  if (!arr || !arr.length) {
    return [];
  }

  let flat: ParsedObject[] = [];

  arr.forEach((item: ParsedObject) => {
    flat.push(item);
    if (
      Array.isArray(item.children) &&
      item.type === EditorCustomElements.Style
    ) {
      flat = flat.concat(flattenNodes(item.children as ParsedObject[]));
    }
  });

  return flat;
};

import styled from 'styled-components';
import { Skeleton } from '../Skeleton';

export const AvatarWrapper = styled.div`
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  width: ${(props) => props.theme.spacing.get(6)};
  height: ${(props) => props.theme.spacing.get(6)};

  &:not(:first-child) {
    border-left: 2px solid ${(props) => props.theme.palette.background.main};
    margin-left: ${(props) => props.theme.spacing.get(-1.5)};
  }
`;

export const AvatarSkeleton = styled(Skeleton)`
  width: 100%;
  height: 100%;
`;

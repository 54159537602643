import { type FC, useEffect, useState, Suspense, lazy, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme, baseTheme, ModalsContainer } from '@teleforce/ui';
import { AuthProvider } from '@teleforce/core';
import { I18nextProvider } from 'react-i18next';
import { Notifications } from '@teleforce/web-base';
import { useUnit } from 'effector-react';
import { GlobalStyles } from './GlobalStyles';

import i18n from './modules/shared/i18n';
import {
  telegramService,
  isMobileDevice,
  RotationPlaceHolder,
} from './modules/shared';

const LazyRoot = lazy(() => import('./modules/app/AppRoot'));

export const App: FC = () => {
  const [isLandscape, setIsLandscape] = useState(false);

  const theme = useUnit(telegramService.$theme);
  const themeConfigs = useMemo(
    () => (theme === 'light' ? baseTheme : darkTheme),
    [theme],
  );

  useEffect(() => {
    Telegram.WebApp.headerColor = themeConfigs.palette.neutral100;
  }, [themeConfigs]);

  useEffect(() => {
    const handleOrientationChange = () => {
      if (!isMobileDevice()) {
        return;
      }
      if (window?.screen?.orientation?.type) {
        if (window.screen.orientation.type.includes('portrait')) {
          setIsLandscape(false);
        } else if (window.screen.orientation.type.includes('landscape')) {
          setIsLandscape(true);
        }
      } else if (window.matchMedia) {
        const isViewLandscape = window.matchMedia(
          '(orientation: landscape)',
        ).matches;
        setIsLandscape(isViewLandscape);
      }
    };
    handleOrientationChange();

    window.addEventListener('resize', handleOrientationChange);
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <ThemeProvider theme={themeConfigs}>
      <GlobalStyles />
      <AuthProvider autoInitialize>
        <I18nextProvider i18n={i18n}>
          <Suspense>
            <LazyRoot />
          </Suspense>
          {isLandscape && <RotationPlaceHolder />}
          <Notifications
            maxItems={3}
            preventDuplicate
            anchorOrigin="bottom-center"
            animation="fade"
          />
        </I18nextProvider>
        <ModalsContainer />
      </AuthProvider>
    </ThemeProvider>
  );
};

import type { Config } from './types';
import packageTwa from '../../package.json';

// eslint-disable-next-line import/no-mutable-exports
export let config: Config;

export const initConfig = async () => {
  config = {
    api: {
      baseUrl: 'https://api.teleforce.pro',
    },
    test_token: import.meta.env.TELEFORCE_TEST_TOKEN || '',
    test_user: import.meta.env.TELEFORCE_TEST_USER || '',
    mode: import.meta.env.TELEFORCE_MODE ?? 'production',
    appVersion: packageTwa.version ?? '',
    appUrl:
      import.meta.env.TELEFORCE_APP_URL ||
      'http://t.me/TeleforceDevBot/Teleforce',
    botUrl:
      import.meta.env.TELEFORCE_APP_BOT_URL || 'http://t.me/TeleforceDevBot',
    appName: import.meta.env.TELEFORCE_APP_NAME || 'Teleforce Dev',
  };
};

import styled, { css } from 'styled-components';

export const ExpressionWrapper = styled.span`
  ${({ theme }) => css`
    ${theme.typography.textSM};
    background: ${theme.palette.warning10};
    padding: ${theme.spacing.get(1, 2)};
    margin: ${theme.spacing.get(0, 1)};
  `}
`;

import { Expose, plainToClass } from 'class-transformer';
import type { ChatDTO } from '../types';

export class Chat {
  id!: number;

  chat?: string;

  title?: string;

  description?: string;

  space_id?: number;

  @Expose({ name: 'chat_type' })
  type?: 'group' | 'channel' | 'supergroup';

  @Expose({ name: 'chat_url' })
  link?: string;

  static fromDTO(dto: ChatDTO): Chat {
    return plainToClass(Chat, dto);
  }
}
